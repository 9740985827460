import { useTranslation } from 'next-i18next'
import styled from 'styled-components'

import { colors } from '@/styles'
import { useFilters } from '@/hooks/use-filters'
import useFilterLabels from '@/features/Listings/hooks/use-filter-labels'
import { useFiltersModal } from '@/features/Listings/hooks/use-filters-modal'
import { FiltersIcon } from '@/components/icons'
import { Label } from '@/components/text'

const Button = styled.button`
  background: none;
  border: none;
  padding: 0 4px;
  margin: 0 4px;
  color: ${colors.black};
`

const ClickWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  pointer-events: none;
  position: relative;

  > svg {
    transform: none !important;
    height: 15px !important;
    width: 15px !important;
    margin: 1px 0;
  }
`

const FilterLabel = styled(Label)`
  font-size: 10px;
  line-height: 12px;
`

const Balloon = styled(Label)`
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: ${colors.red64};
  border-radius: 12px;
  height: 13px;
  min-width: 13px;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  color: ${colors.white};
  font-weight: 600;
  padding: 0 3px;
  z-index: 1;
`

const FiltersButton = ({ boundaries = {}, centerPoint }) => {
  const { t } = useTranslation()
  const [filters] = useFilters()
  const [getFilterLabels] = useFilterLabels()
  const filterList = getFilterLabels(filters)
  const filterCount = filterList.length || 0
  const isUniversityPage =
    window.location.href.includes('offcampushousing') ||
    window.location.href.includes('och-dev.machobear.ca') ||
    window.location.href.includes('offcampus-housing.com')

  const [showFiltersModal] = useFiltersModal({
    isMapMode: true,
    isUniversityPage,
    boundaries,
    centerPoint
  })

  const clickId = 'map-filters-open-click'
  const handleClick = () => {
    window.dataLayer.push({
      event: clickId
    })
    showFiltersModal()
  }

  return (
    <Button
      // onMouseDown/onTouchEnd instead of onClick due this component
      // being used within react-select which does weird things
      // https://github.com/JedWatson/react-select/issues/3117
      onMouseDown={handleClick}
      onTouchEnd={handleClick}
      className="filters-button-mobile"
      id={clickId}
    >
      <ClickWrapper>
        {filterCount > 0 && <Balloon>{filterCount}</Balloon>}
        <FiltersIcon />
        <FilterLabel>
          {t('filters:b.listing_filter.edit.filters.label', 'Filters')}
        </FilterLabel>
      </ClickWrapper>
    </Button>
  )
}

export default FiltersButton
