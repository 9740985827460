import { components, GroupHeadingProps } from 'react-select'

const GroupHeading = (props: GroupHeadingProps) => {
  if (!props.data.label) return null

  return (
    <components.GroupHeading {...props}>
      {props.children}
    </components.GroupHeading>
  )
}

export default GroupHeading
