import { vars } from '@machobear/design-system/css'
import { CSSObjectWithLabel, OptionProps, StylesConfig } from 'react-select'

import { CssUnit } from '@/types'
import { colors } from '@/styles'

const selectedColor = 'rgba(193, 212, 233, 0.2)'

interface CustomStyles extends StylesConfig {
  height?: CssUnit
  width?: CssUnit
  showLarge?: boolean
}

const selectStyles = (styles: Partial<CustomStyles> = {}): CustomStyles => {
  const showLarge = styles.showLarge
  const radius = 6
  const primaryIcon = showLarge ? 26 : 20
  const defaultFont = {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '16px',
    fontFamily: "'Inter', sans-serif"
  }
  const placeholderFont = {
    fontSize: showLarge ? 18 : 16,
    lineHeight: showLarge ? '24px' : '16px'
  }

  return {
    container: (provided: CSSObjectWithLabel) => {
      return {
        ...provided,
        margin: '0 auto',
        width: '100%',
        maxWidth: showLarge ? styles.width : '500px',
        zIndex: 2,
        ...defaultFont,
        ...styles.container
      }
    },
    option: (provided: CSSObjectWithLabel, state: OptionProps) => ({
      ...provided,
      backgroundColor: state.isFocused ? selectedColor : 'transparent',
      color: vars.foregroundColor.secondary,
      cursor: 'pointer',
      lineHeight: '16px',
      padding: '12px 16px',
      ...styles.option
    }),
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      marginTop: 4,
      ...styles.menu,
      pointerEvents: 'auto',
      textAlign: 'left',
      width: showLarge ? styles.width : '100%',
      borderRadius: radius,
      marginLeft: 0,
      boxShadow: 'none',
      border: `1px solid ${vars.borderColor.neutralLight}`,
      overflow: 'hidden'
    }),
    menuList: (provided: CSSObjectWithLabel) => ({
      ...provided,
      padding: 0,
      // Uses visualViewport to account for iOS keyboard,
      // 10px padding, 122px of items above dropdown to allow dropdown
      // to use remaining viewport space.
      maxHeight: 'clamp(145px, var(--vh, 1vh) * 100 - 122px - 10px, 400px)'
    }),
    control: (provided: CSSObjectWithLabel) => ({
      ...provided,
      pointerEvents: 'auto',
      overflow: 'hidden',
      height: styles.height || showLarge ? 70 : 60,
      width: showLarge ? '100%' : styles.width,
      cursor: 'input',
      border: `1px solid ${vars.borderColor.field}`,
      borderRadius: radius,
      backgroundColor: vars.backgroundColor.white,
      boxShadow: 'none',
      '&:hover': {
        borderColor: vars.borderColor.field
      },
      transition: 'none',
      marginLeft: 0,
      ...styles.control
    }),
    indicatorSeparator: () => ({
      fontSize: 16
    }),
    indicatorsContainer: () => ({
      height: showLarge ? '68px' : '58px',
      alignSelf: 'flex-start',
      display: 'flex'
    }),
    dropdownIndicator: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: colors.regular,
      padding: 0,
      minWidth: showLarge ? '72px' : '56px',
      svg: {
        height: primaryIcon,
        width: primaryIcon
      }
    }),
    clearIndicator: (provided: CSSObjectWithLabel) => ({
      ...provided,
      background: colors.darkGrey,
      borderRadius: radius,
      height: 15,
      width: 15,
      padding: 0,
      svg: {
        color: colors.white,
        height: 15,
        width: 15,
        padding: 1
      }
    }),
    singleValue: (provided: CSSObjectWithLabel) => ({
      ...provided,
      padding: '4px 0'
    }),
    placeholder: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: vars.foregroundColor.tertiary,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: '92%',
      fontWeight: 500,
      padding: '4px 0',
      ...placeholderFont,
      ...styles.placeholder
    }),
    valueContainer: (provided: CSSObjectWithLabel) => ({
      ...provided,
      maxWidth: '86%',
      padding: showLarge ? '0px 22px' : '0px 14px',
      fontWeight: 500,
      ...placeholderFont
    }),
    group: (provided: CSSObjectWithLabel) => ({
      ...provided,
      padding: '12px 0px',
      position: 'relative',
      ':after': {
        content: '""',
        position: 'absolute',
        background: colors.grey88,
        height: '1px',
        width: 'calc(100% - 48px)',
        top: 0,
        left: 24
      },
      ':nth-of-type(2):after': {
        width: '100%',
        left: 0
      }
    }),
    groupHeading: (provided: CSSObjectWithLabel) => ({
      ...provided,
      margin: '12px 0',
      paddingLeft: 16
    })
  }
}

export default selectStyles
