import { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { components, OptionProps } from 'react-select'

import { colors } from '@/styles'
import { CURRENT_LOCATION } from '@/utils/location'

const Container = styled.div<{
  isLocationOption?: boolean
  isSearchHistoryOption?: boolean
}>`
  display: flex;
  text-decoration: none;
  margin-left: 30px;

  ${props =>
    props.isLocationOption &&
    `
    margin: 12px 0px;
  `}

  ${props =>
    props.isSearchHistoryOption &&
    `
    margin-left: 12px;
  `}

  strong {
    font-weight: 600;
  }
`

const AddressText = styled.div<{ isSearchHistoryOption?: boolean }>`
  font-size: 12px;
  line-height: 12px;
  margin-top: 4px;
  margin-left: 30px;

  ${props =>
    props.isSearchHistoryOption &&
    `
    margin-left: 12px;
  `}

  strong {
    font-weight: 600;
  }
`

const CompanyContainer = styled.div<{
  $selected?: boolean
}>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: ${props => (props.$selected ? colors.black : colors.grey24)};
  background-color: ${props =>
    props.$selected ? colors.blue96 : 'transparent'};
  line-height: 20px;
  padding: 12px 16px;
  margin: 0;
`

const highlightSearchQuery = (searchQuery: string, label: string) => {
  if (searchQuery.length < 2 || typeof label !== 'string') return label
  const searchTerms = searchQuery.trim().split(/\s+/).join('|')
  const regex = new RegExp(`(${searchTerms})`, 'gi')
  const parts = label.split(regex)
  const highlightedText = parts.map((part, index) =>
    regex.test(part) ? <strong key={index}>{part}</strong> : part
  )
  return <div>{highlightedText}</div>
}

const Option = (props: OptionProps<any>) => {
  const { data, children, selectProps } = props
  const isLocationOption = data.value === CURRENT_LOCATION
  const isSearchHistoryOption = data.history
  const searchQuery = selectProps.inputValue

  const content = (
    <Container
      isLocationOption={isLocationOption}
      isSearchHistoryOption={isSearchHistoryOption}
    >
      {highlightSearchQuery(searchQuery, children || data.parsedAddress)}
    </Container>
  )

  const isSelected = props.isSelected || props.isFocused

  return !data.url ? (
    <components.Option {...props}>{content}</components.Option>
  ) : (
    <CompanyContainer
      $selected={isSelected}
      {...(props.innerProps as HTMLAttributes<HTMLDivElement>)}
    >
      {content}
      {children && data.parsedAddress && (
        <AddressText isSearchHistoryOption={isSearchHistoryOption}>
          {highlightSearchQuery(searchQuery, data.parsedAddress)}
        </AddressText>
      )}
    </CompanyContainer>
  )
}

export default Option
