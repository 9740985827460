import { useRouter } from 'next/router'
import styled from 'styled-components'
import { components, DropdownIndicatorProps } from 'react-select'

import { breakpoints, colors } from '@/styles'
import useMediaQuery from '@/hooks/use-media-query'
import { SearchIcon } from '@/components/icons'

import MobileButtons from './mobile-buttons'

const SearchWrapper = styled.div<{ customColor?: string }>`
  background-color: ${props => props.customColor || colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const DropdownIndicator = (
  props: DropdownIndicatorProps & {
    customColor?: string
    showFilters?: boolean
    boundaries?: {
      top_left: { lat: number; lng: number }
      bottom_right: { lat: number; lng: number }
    }
    centerPoint?: number[]
  }
) => {
  const { customColor, showFilters, boundaries, centerPoint, ...rest } = props
  const router = useRouter()
  const isSearchPage =
    router.pathname.includes('rental-listings') ||
    router.pathname.includes('company')

  const displayTablet = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const displayButtons = (isSearchPage || showFilters) && displayTablet

  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator
        {...rest}
        innerProps={{
          ...rest.innerProps,
          ...(displayButtons
            ? {
                // Prevent default react-select behavior
                // https://github.com/JedWatson/react-select/issues/3117
                onMouseDown: e => {
                  e.stopPropagation()
                  e.preventDefault()
                },
                onTouchEnd: e => {
                  e.stopPropagation()
                  e.preventDefault()
                }
              }
            : {})
        }}
      >
        {displayButtons ? (
          <MobileButtons
            hideAlertButton={showFilters}
            boundaries={boundaries}
            centerPoint={centerPoint}
          />
        ) : (
          <SearchWrapper customColor={customColor}>
            <SearchIcon />
          </SearchWrapper>
        )}
      </components.DropdownIndicator>
    )
  )
}

export default DropdownIndicator
