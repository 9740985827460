import { components, ControlProps } from 'react-select'

const handleOnClick = () => {
  window.dataLayer.push({
    event: 'header-search-bar'
  })
}

const Control = (props: ControlProps) => {
  return (
    <div onClick={handleOnClick}>
      <components.Control {...props}>{props.children}</components.Control>
    </div>
  )
}

export default Control
