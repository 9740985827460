import { isEmptyOrNull } from '@/utils'
import useFormattedFilters from '@/hooks/use-formatted-filters'

import AlertButton from './alert-button'
import FiltersButton from './filters-button'

const MobileButtons = ({
  hideAlertButton = false,
  boundaries,
  centerPoint
}) => {
  const filters = useFormattedFilters()
  const disabled = isEmptyOrNull(filters.cities)
  return (
    <>
      <FiltersButton centerPoint={centerPoint} boundaries={boundaries} />
      {!hideAlertButton && (
        <AlertButton disabled={disabled} id="create-alert-main-click" />
      )}
    </>
  )
}

export default MobileButtons
