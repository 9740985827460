import { useTranslation } from 'next-i18next'
import styled from 'styled-components'

import { colors } from '@/styles'
import { makeAlertButton } from '@/features/Listings/buttons'
import { AlertIcon } from '@/components/icons'
import { Label } from '@/components/text'

const Button = styled.button`
  background: none;
  border: none;
  padding: 0 4px;
  margin: 0 12px 0 4px;
  color: ${colors.black};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`

const ClickWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  pointer-events: none;
  position: relative;

  > svg {
    path {
      fill: ${colors.grey16};
    }
    transform: none !important;
    height: 15px !important;
    width: 12px !important;
    margin: 1px 0;
  }
`

const FilterLabel = styled(Label)`
  font-size: 10px;
  line-height: 12px;
`

export const AlertButton = makeAlertButton(
  props => {
    const { t } = useTranslation()
    const label = t('filters:b.listing.alert.create.button', 'Create Alert')

    return (
      <Button
        className="alert-button-mobile"
        aria-label={label}
        title={label}
        {...props}
      >
        <ClickWrapper>
          <AlertIcon />
          <FilterLabel>
            {t('filters:b.listing_filter.edit.alert.label', 'Alert')}
          </FilterLabel>
        </ClickWrapper>
      </Button>
    )
  },
  'AlertButton',
  // This bool is passed to indicate the button is within react-select.
  // We have to use onMouseDown/onTouchEnd instead of onClick due to
  // react-select doing weird things that we can't control
  true
)

export default AlertButton
